import axios from "axios";
import { URL } from "../utils/Config";
import { getUser } from "../utils/Util";

const instance = axios.create({
  baseURL: URL.BASE_API_URL,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

const getAuthHeaders = (accessToken: string) => {
  const headers: any = {
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  return headers;
};
const getFullAuthHeaders = () => {
  const user = getUser();
  const headers: any = {
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
    Authorization: `Bearer ${user.accessToken}`,
    authUser: user.candidateUniqueId,
  };
  return headers;
};
const getMultipartAuthHeaders = (accessToken: string) => ({
  "Access-Control-Allow-Credentials": true,
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${accessToken}`,
});

const getApiClient = (headers: any = {}, baseURL?: string) => {
  return axios.create({
    baseURL: baseURL || URL.BASE_API_URL,
    headers,
    validateStatus: (status) => {
      return status >= 200 && status < 600;
    },
  });
};

export { instance, getApiClient, getAuthHeaders, getMultipartAuthHeaders, getFullAuthHeaders };
