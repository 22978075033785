import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../screens/login/LoginPage";
// import Home from "../screens/home/Home";
import Dashboard from "../screens/dashboard/Dashboard";
import CCEActivities from "../screens/activities/cceActivities/CCEActivities";
import CHMActivities from "../screens/activities/chmActivities/CHMActivities";
import CHMMasters from "../screens/activities/chmMasters/CHMMasters";
import IECActivities from "../screens/activities/iecActivities/IECActivities";
import HomePage from "../screens/home/HomePage";
import SettingPage from "../screens/setting/SettingPage";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/cceActivities" element={<CCEActivities />} />
      <Route path="/chmActivities" element={<CHMActivities />} />
      <Route path="/chmMasters" element={<CHMMasters />} />
      <Route path="/iecActivities" element={<IECActivities />} />
    </Routes>
  );
};
