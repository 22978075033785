import "../components/CPagination.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const CPagination = ({
  storeCHMDashboardData,
  storeCHMPaginationData,
  changePaginationLimit,
  prevPage,
  nextPage,
}: any) => {
  const { limit, offset, selectedLimit } = storeCHMPaginationData;

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  return (
    <div className="footer">
      <p style={{ fontWeight: "600", fontSize: "14px", margin: "0" }}>
        {isMobile() ? "Records:" : "No. of Records:"}
        {storeCHMDashboardData.count > limit
          ? limit
          : storeCHMDashboardData.count}
        /{storeCHMDashboardData.count}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isMobile() ? 'column' : 'row',
        }}
      >
        <FormControl>
          <RadioGroup
            row
            value={selectedLimit}
            onChange={(event: any) => {
              changePaginationLimit(Number(event.target.value));
            }}
          >
            <FormControlLabel
              value="25"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label="25"
            />
            <FormControlLabel
              value="50"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label="50"
            />
            <FormControlLabel
              value="100"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label="100"
            />
          </RadioGroup>
        </FormControl>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <label style={{ fontWeight: "550", fontSize: "14px" }}>
            Pagination :
          </label>
          <img
            src={require("../assets/img/prev.png")}
            alt=""
            style={{ cursor: "pointer", marginRight: "5px", marginLeft: "5px" }}
            onClick={() => {
              prevPage(limit);
            }}
          />

          <label style={{ fontWeight: "550", fontSize: "14px" }}>
            {offset} - {offset + selectedLimit}
          </label>
          <img
            src={require("../assets/img/next.svg").default}
            alt=""
            style={{ cursor: "pointer", marginRight: "5px", marginLeft: "5px" }}
            onClick={() => {
              nextPage(limit);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CPagination;
