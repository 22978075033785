import React from "react";
import { StickyHeadTable } from "../../../components/TableComponents";
import { createDataTable } from "../../../utils/TableUtils";
import { Column, Data } from "../../../models/TableModels";

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
];

const rows: Data[] = [
  createDataTable("India", "IN", 1324171354, 3287263),
  createDataTable("Italy", "IT", 60483973, 301340),
  createDataTable("United States", "US", 327167434, 9833520),
  createDataTable("Canada", "CA", 37602103, 9984670),
  createDataTable("Russia", "RU", 146793744, 17098246),
  createDataTable("Nigeria", "NG", 200962417, 923768),
  createDataTable("Brazil", "BR", 210147125, 8515767),
];
const IECActivities = () => {
  return (
    <>
      <div>IEC Activities</div>
      <StickyHeadTable headerItems={columns} bodyItems={rows} />
    </>
  );
};

export default IECActivities;
