import { URL } from "../utils/Config";
import { STORAGE_KEY } from "../utils/Constant";
import { getFromLocalStorage } from "../utils/Util";
import { getApiClient, getAuthHeaders, getFullAuthHeaders, instance } from "./base";
import { METHOD } from "./ServiceConstants";

export const LoginV = (data: any) => {
  return getApiClient(
    null,
    URL.BASE_URL_REST_FULL_ITUS
  )({
    method: METHOD.POST,
    url: URL.LOGIN_V,
    data,
  });
};


export const locationDetails = (data: any) => {
  return getApiClient(
    getAuthHeaders(getFromLocalStorage(STORAGE_KEY.ITUS)),
    URL.BASE_URL_REST_FULL_ITUS
  )({
    method: METHOD.POST,
    url: URL.PINCODE,
    data,
  });
};

export const onPostSSOLogin = (data: any) => {
  return instance({
    method: METHOD.POST,
    url: URL.POST_SSO_VALIDATION,
    data,
  });
};

export const logout = () => {
  return getApiClient(getFullAuthHeaders())({
    method: METHOD.POST,
    url: URL.LOGOUT,
  });
};

export const registeredEmp = (params: any) => {
  return getApiClient(getFullAuthHeaders())({
    method: METHOD.GET,
    url: URL.LIST_OF_EMPLOYEES_STATUS,
    params,
  });
};
