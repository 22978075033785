import React from "react";
import { StickyHeadTable } from "../../../components/TableComponents";
import { createDataTable } from "../../../utils/TableUtils";
import { Column, Column1, Data } from "../../../models/TableModels";

const columns: Column1[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
];

const rows: Data[] = [
  createDataTable("Italy", "IT", 60483973, 301340),
  createDataTable("United States", "US", 327167434, 9833520),
  createDataTable("India", "IN", 1324171354, 3287263),
  createDataTable("Canada", "CA", 37602103, 9984670),
  createDataTable("Australia", "AU", 25475400, 7692024),
  createDataTable("Germany", "DE", 83019200, 357578),
  createDataTable("Ireland", "IE", 4857000, 70273),
  createDataTable("Mexico", "MX", 126577691, 1972550),
  createDataTable("Japan", "JP", 126317000, 377973),
  createDataTable("France", "FR", 67022000, 640679),
  createDataTable("United Kingdom", "GB", 67545757, 242495),
  createDataTable("Russia", "RU", 146793744, 17098246),
  createDataTable("Nigeria", "NG", 200962417, 923768),
  createDataTable("Brazil", "BR", 210147125, 8515767),
];
const CCEActivities = () => {
  return (
    <>
      <div>CCE Activities </div>
      <StickyHeadTable headerItems={columns} bodyItems={rows} />
    </>
  );
};

export default CCEActivities;
