import { createSlice } from "@reduxjs/toolkit";
import { LIST_VIEW_ITEM, SIDE_MENU_STATUS } from "../../utils/Constant";
import { DashboardInitialState } from "../../models/DashboardModel";

const initialState: DashboardInitialState = {
  selectedMenu: "home",
  isOpenSideMenu: true,
  isMobile: false,
  listOrView: LIST_VIEW_ITEM.LIST_ITEMS
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    updateSideMenuStatus: (state, action) => {
      if (action.payload === SIDE_MENU_STATUS.OPEN) {
        state.isOpenSideMenu = true;
      } else if (action.payload === SIDE_MENU_STATUS.CLOSE) {
        state.isOpenSideMenu = false;
      } else {
        state.isOpenSideMenu = !state.isOpenSideMenu;
      }
    },
    updateDeviceStatus: (state, action) => {
      state.isMobile = action.payload.isMobile;
    },
    updateListViewItemsStatus: (state, action) => {
      state.listOrView = action.payload;
    },
  },
});
export const dashboardSelector = (state: any) => state.dashboard;
export const { updateSelectedMenu, updateSideMenuStatus, updateDeviceStatus, updateListViewItemsStatus } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
