import { Label } from "reactstrap";
import "./SearchBox.css";
import React from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface IKDatePickerProps {
  placeHolder?: string;
  label?: string;
  value?: Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date) => void;
  disabled?: boolean;
}

const KDatePicker: React.FunctionComponent<IKDatePickerProps> = (props) => {
  return (
    <div>
      <Label className="mb-1 text-start search-label text-muted">
        {props.label}
      </Label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            disabled={props.disabled}
            minDate={props.minDate ? moment(props.minDate) : undefined}
            maxDate={props.maxDate ? moment(props.maxDate) : undefined}
            className="date-picker"
            value={props.value ? moment(props.value, "DD-MM-YYYY") : null}
            format="DD-MM-YYYY"
            onChange={(newValue) => {
              props.onChange(newValue ? newValue.toDate() : moment().toDate());
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  borderColor: "transparent",
                  borderRadius: "0px !important",
                },
                "&:hover fieldset": {
                  borderRadius: "5px !important",
                  border: "1px solid transparent !important",
                },
                "&.Mui-focused fieldset": {
                  borderRadius: "5px !important",
                  border: "1px solid #0b67c3 !important",
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default KDatePicker;
