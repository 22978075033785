const DataNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
      }}
    >
      <div>
        <img
          src={require("../assets/img/data_not_found.svg").default}
          style={{ height: 300, width: 300 }}
          alt=""
        />
        <h3 style={{ textAlign: "center" }}> Data Not Found </h3>
      </div>
    </div>
  );
};

export default DataNotFound;
