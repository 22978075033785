type DataListType = {
  label: string | undefined;
  value: number | string | undefined;
}[];

type Response = {
  id?: number;
  value?: string;
};

export class FormUtils {
  static convertDataList(data: Response[]): DataListType {
    return data.map((item: Response) => ({
      label: item.value,
      value: item.id,
    }));
  }

  static convertCropDataList(data: Response[]): DataListType {
    return data.map((item: any) => ({
      label: item.mobile,
      value: item.mobile,
    }));
  }

  static convertSurveyorDataList(data: any[]) {
    return data.map((item: any) => ({
      label: `${item.mobile} - ${item.name}`,
      value: `${item.mobile}`,
    }));
  }
}

export interface SelectOptionType {
  value: number | string;
  label: number | string;
}
