import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { isAPISuccess } from "../../utils/Util";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import CenterSpinner from "../../components/CenterSpinner";
import { useState } from "react";
import { onLogout, setLocalLogout } from "../../redux/slices/loginSlice";
import { Button } from "reactstrap";

interface ISettingPageProps {}

const SettingPage: React.FunctionComponent<ISettingPageProps> = (props) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { instance } = useMsal();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const callLogout = () => {
    setShowLoader(true);
    dispatch(onLogout())
      .unwrap()
      .then((fulfilled) => {
        setShowLoader(false);
        if (isAPISuccess(fulfilled)) {
          toast.success(fulfilled.responseInformation.responseMessage);
        }
        dispatch(setLocalLogout({}));
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };
  return (
    <div className="d-flex justify-content-center wh-100">
      <div>
        <Button variant="primary" onClick={callLogout}>
          Logout
        </Button>
      </div>
      <CenterSpinner showLoader={showLoader} className="spinner-style" />
    </div>
  );
};

export default SettingPage;
