import { Data } from "../models/TableModels";
import { RealData } from "../models/TableModels";

export const createDataTables= (
  
  CHM: string,
State: string,
District: string,
Block: string,
CropNameAsperApplication: string,
CropNameOnField: string,
CHMDate : number,
View : string,
): RealData => {
  
  return { 
    CHM,
    State,
    District,
    Block,
    CropNameAsperApplication,
    CropNameOnField,
    CHMDate ,
    View,};
}
 

export const createDataTable = (
  name: string,
  code: string,
  population: number,
  size: number,
  
): Data => {
  const density = population / size;
  return { name, code, population, size, density,
    };
}